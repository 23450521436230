import React, {ReactElement, useCallback} from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {Icon} from 'shared/helpers';
import {Category} from 'shared/components/Product/Category';
import {ItemColumn, useItemColumn} from 'shared/components/Product/ItemColumn';
import styled from 'styled-components';

type Category = {
    id: number;
    noImage: boolean;
    action: () => void;
    imageUrl: string;
    name: string;
    isSubCategory: boolean;
};

type CategoriesType = {
    isProduct: boolean;
    categories: Category[];
    loader: boolean;
};

export const Categories = ({
    isProduct,
    categories,
    loader,
}: CategoriesType): ReactElement => {
    const {jobId, roomId} = useParams();
    const [searchParams] = useSearchParams();
    const category = searchParams.get('category') || false;
    const subCategory = searchParams.get('subCategory') || false;
    const {loaders} = useItemColumn(loader);

    const navigate = useNavigate();

    const favouriteClickHandler = useCallback(() => {
        navigate(`?favourites=1`);
    }, [navigate]);

    const categoryClickHandler = useCallback(
        (selectedCategory: Category) => {
            let link = `/v2/job/${jobId}/hardware?category=${selectedCategory.id}`;

            if (roomId) {
                if (selectedCategory.isSubCategory) {
                    link = `/v2/job/${jobId}/room/${roomId}/product?category=${category}&subCategory=${selectedCategory.id}`;
                } else
                    link = `/v2/job/${jobId}/room/${roomId}/product?category=${selectedCategory.id}`;
            }

            navigate(link);
        },
        [roomId, navigate, category]
    );

    return (
        <CategoryContainer
            className="hardwareCategories"
            $fixed={categories ? categories.length < 5 : false}>
            {loader ? (
                <LoaderContainer className="hardwareItems">
                    {loaders}
                </LoaderContainer>
            ) : null}
            {loader || category || subCategory ? null : (
                <ItemColumn
                    className="hardwareCategory"
                    onClick={favouriteClickHandler}>
                    <div className="imageContainer favourite-container">
                        <Icon
                            className="favourite"
                            iconName="Favourite-item-selected.svg"
                        />
                    </div>
                    <div className="categoryName">Favourites</div>
                </ItemColumn>
            )}
            {!loader
                ? categories.map((category) => {
                      return (
                          <Category
                              category={category}
                              categoryClickHandler={categoryClickHandler}
                              key={category.id}
                              isProduct={isProduct}
                          />
                      );
                  })
                : null}
        </CategoryContainer>
    );
};
export const CategoryContainer = styled.div<{$fixed?: boolean}>`
    display: grid;
    align-items: center;
    grid-gap: 15px;

    ${({$fixed: $clamp}) => {
        if ($clamp) {
            return `
                grid-template-columns: repeat(auto-fit, 220px);
                @media (max-width: 768px) {
                    grid-template-columns: repeat(auto-fit, 150px);
                }
            `;
        } else {
            return `
                grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
                @media (max-width: 768px) {
                    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
                }
            `;
        }
    }}
`;

const LoaderContainer = styled.div`
    display: flex;
    gap: 15px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
`;
